import React from 'react'

const NotFoundPage = () => (
    <div className='content'>
        <div className="row">
            <div className="col-sm-12">
                <h1>404 - דף האינטרנט שביקשת לא נמצא</h1>
<p>

</p>
            </div>

        </div>
    </div>
)

export default NotFoundPage
